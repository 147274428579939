import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendingSizeConditions() {
    return axiosIns.get('/admin/vending_size_conditions')
  },
  createVendingSizeCondition({
    adjective,
    threshold,
    vendibleId,
    vendibleType,
    vendibleTagId,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingSizeCondition: {
        adjective,
        threshold,
        vendibleId,
        vendibleType,
        vendibleTagId,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.post('/admin/vending_size_conditions', formData)
  },
  updateVendingSizeCondition({
    id,
    adjective,
    threshold,
    vendibleId,
    vendibleType,
    vendibleTagId,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingSizeCondition: {
        adjective,
        threshold,
        vendibleId,
        vendibleType,
        vendibleTagId,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.put(`/admin/vending_size_conditions/${id}`, formData)
  },
  deleteVendingSizeCondition(id) {
    return axiosIns.delete(`/admin/vending_size_conditions/${id}`)
  },
}
