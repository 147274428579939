var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('common-conditions',{attrs:{"headers-ext":_vm.headers,"items":_vm.vendingSizeConditions,"validator":function (currentForm) { return Object.values(_vm.isValid).every(function (bool) { return bool; }); },"threshold-suffix":"個","is-submitting":_vm.isSubmitting},on:{"submit":_vm.submit,"cancel":_vm.cancelForm,"edit":function($event){_vm.currentForm = Object.assign({}, {id: $event.id}, $event.attributes)},"destroy":function($event){return _vm.destroy($event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.relationType),callback:function ($$v) {_vm.relationType=$$v},expression:"relationType"}},_vm._l((_vm.relationTypes),function(text,key){return _c('v-radio',{key:("relationTypes[" + key + "]"),attrs:{"label":text,"value":key,"disabled":!!_vm.currentForm.id},on:{"change":function($event){_vm.currentForm.vendibleId = null;
            _vm.currentForm.vendibleType = null;
            _vm.currentForm.vendibleTagId = null;
            _vm.isOpenVendibleTagVendibles = false}}})}),1),(_vm.relationType === 'vendible')?_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.vendibleItems,"item-value":function (item) {
          if (item.attributes) { return { vendibleId: item.id, vendibleType: item.attributes.vendibleType } }
        },"value-comparator":function (a, b) {
          if (a && b) { return +a.vendibleId === +b.vendibleId && a.vendibleType === b.vendibleType }
        },"label":"ターゲット","error":!_vm.isValid.relation,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.vendibleId = $event.vendibleId;
          _vm.currentForm.vendibleType = $event.vendibleType;
          _vm.currentForm.vendibleTagId = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.attributes.name)+" "),_c('small',{staticClass:"ml-2 text--secondary"},[_vm._v(" "+_vm._s(("¥" + (item.attributes.sellingPrice.toLocaleString())))+" ")])])]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [(!item.attributes)?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item.header)}})]:_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.attributes.name)+" "),_c('small',{staticClass:"ml-2 text--secondary"},[_vm._v(" "+_vm._s(("¥" + (item.attributes.sellingPrice.toLocaleString())))+" ")])])],1)]}}],null,false,3748544562)}):_vm._e(),(_vm.relationType === 'vendibleTag')?_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.vendibleTags,"item-value":function (item) {
          if (item.attributes) { return { vendibleTagId: item.id } }
        },"value-comparator":function (a, b) {
          if (a && b) { return +a.vendibleTagId === +b.vendibleTagId }
        },"item-text":"attributes.name","label":"ターゲット","error":!_vm.isValid.relation,"append-outer-icon":_vm.isOpenVendibleTagVendibles ? _vm.icons.mdiEye : _vm.icons.mdiEyeOffOutline,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.vendibleId = null;
          _vm.currentForm.vendibleType = null;
          _vm.currentForm.vendibleTagId = $event.vendibleTagId},"click:append-outer":function($event){_vm.isOpenVendibleTagVendibles = !_vm.isOpenVendibleTagVendibles}}}):_vm._e(),(_vm.isOpenVendibleTagVendibles)?_c('div',[(_vm.vendibleTagVendibles.length > 0)?_c('div',_vm._l((_vm.vendibleTagVendibles),function(vendibleTagVendible,vendibleTagVendibleIdx){return _c('v-list-item',{key:("vendibleTagVendibles[" + vendibleTagVendibleIdx + "]"),staticClass:"secondary--text"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(vendibleTagVendible.attributes.name)+" "),_c('small',{staticClass:"ml-2 text--secondary"},[_vm._v(" "+_vm._s(("¥" + (vendibleTagVendible.attributes.sellingPrice.toLocaleString())))+" ")])])],1)],1)}),1):_c('span',[_vm._v("関連する商品がありません")])]):_vm._e(),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.tableFilters,"item-value":function (item) {
              if (item.attributes) { return { tableFilterId: item.id } }
            },"value-comparator":function (a, b) {
              if (a && b) { return +a.tableFilterId === +b.tableFilterId }
            },"item-text":"attributes.name","label":"卓フィルタ","placeholder":"指定なし","persistent-placeholder":"","no-data-text":"選択可能なデータがありません","suffix":_vm.currentForm.tableFilterId ? 'の卓の' : '',"clearable":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.tableFilterId = $event ? $event.tableFilterId : null},"click:clear":function($event){_vm.currentForm.tableFilterId = null}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.currentForm.calcSalesTarget,"items":_vm.calcSalesTargets,"item-value":"value","item-text":"text","label":"算出対象範囲","error":!_vm.currentForm.calcSalesTarget,"menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.calcSalesTarget = $event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('span',[(item.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [(item.icon)?_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}])})],1)],1)]},proxy:true},{key:"group-header",fn:function(ref){
            var groupBy = ref.groupBy;
            var group = ref.group;
return [(groupBy[0] === 'meta.targetName')?_c('strong',[_vm._v(" "+_vm._s(group)+" ")]):_vm._e(),(groupBy[0] === 'attributes.calcSalesTarget')?_c('strong',[_vm._v(" "+_vm._s(_vm.calcSalesTargets.find(function (o) { return o.value === group; }).text)+" ")]):_vm._e()]}}]),model:{value:(_vm.currentForm),callback:function ($$v) {_vm.currentForm=$$v},expression:"currentForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }