<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <common-conditions
      v-model="currentForm"
      :headers-ext="headers"
      :items="vendingSizeConditions"
      :validator="currentForm => Object.values(isValid).every(bool => bool)"
      threshold-suffix="個"
      :is-submitting="isSubmitting"
      @submit="submit"
      @cancel="cancelForm"
      @edit="currentForm = { id: $event.id, ...$event.attributes }"
      @destroy="destroy($event)"
    >
      <template #input>
        <v-radio-group
          v-model="relationType"
          row
        >
          <v-radio
            v-for="(text, key) in relationTypes"
            :key="`relationTypes[${key}]`"
            :label="text"
            :value="key"
            :disabled="!!currentForm.id"
            @change="
              currentForm.vendibleId = null;
              currentForm.vendibleType = null;
              currentForm.vendibleTagId = null;
              isOpenVendibleTagVendibles = false"
          ></v-radio>
        </v-radio-group>

        <v-select
          v-if="relationType === 'vendible'"
          :value="currentForm"
          :items="vendibleItems"
          :item-value="item => {
            if (item.attributes) return { vendibleId: item.id, vendibleType: item.attributes.vendibleType }
          }"
          :value-comparator="(a, b) => {
            if (a && b) return +a.vendibleId === +b.vendibleId && a.vendibleType === b.vendibleType
          }"
          label="ターゲット"
          :error="!isValid.relation"
          no-data-text="選択可能なデータがありません"
          :menu-props="{ auto: true, offsetY: true }"
          @change="
            currentForm.vendibleId = $event.vendibleId;
            currentForm.vendibleType = $event.vendibleType;
            currentForm.vendibleTagId = null"
        >
          <template #selection="{ item }">
            <span>
              {{ item.attributes.name }}
              <small class="ml-2 text--secondary">
                {{ `¥${item.attributes.sellingPrice.toLocaleString()}` }}
              </small>
            </span>
          </template>

          <template #item="{ item }">
            <template v-if="!item.attributes">
              <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
              <v-list-item-content v-text="item.header" />
            </template>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.attributes.name }}
                <small class="ml-2 text--secondary">
                  {{ `¥${item.attributes.sellingPrice.toLocaleString()}` }}
                </small>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>

        <v-select
          v-if="relationType === 'vendibleTag'"
          :value="currentForm"
          :items="vendibleTags"
          :item-value="item => {
            if (item.attributes) return { vendibleTagId: item.id }
          }"
          :value-comparator="(a, b) => {
            if (a && b) return +a.vendibleTagId === +b.vendibleTagId
          }"
          item-text="attributes.name"
          label="ターゲット"
          :error="!isValid.relation"
          :append-outer-icon="isOpenVendibleTagVendibles ? icons.mdiEye : icons.mdiEyeOffOutline"
          no-data-text="選択可能なデータがありません"
          :menu-props="{ auto: true, offsetY: true }"
          @change="
            currentForm.vendibleId = null;
            currentForm.vendibleType = null;
            currentForm.vendibleTagId = $event.vendibleTagId"
          @click:append-outer="isOpenVendibleTagVendibles = !isOpenVendibleTagVendibles"
        />
        <div v-if="isOpenVendibleTagVendibles">
          <div v-if="vendibleTagVendibles.length > 0">
            <v-list-item
              v-for="(vendibleTagVendible, vendibleTagVendibleIdx) in vendibleTagVendibles"
              :key="`vendibleTagVendibles[${vendibleTagVendibleIdx}]`"
              class="secondary--text"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ vendibleTagVendible.attributes.name }}
                  <small class="ml-2 text--secondary">
                    {{ `¥${vendibleTagVendible.attributes.sellingPrice.toLocaleString()}` }}
                  </small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <span v-else>関連する商品がありません</span>
        </div>

        <v-row class="mt-3">
          <v-col cols="6">
            <v-select
              :value="currentForm"
              :items="tableFilters"
              :item-value="item => {
                if (item.attributes) return { tableFilterId: item.id }
              }"
              :value-comparator="(a, b) => {
                if (a && b) return +a.tableFilterId === +b.tableFilterId
              }"
              item-text="attributes.name"
              label="卓フィルタ"
              placeholder="指定なし"
              persistent-placeholder
              no-data-text="選択可能なデータがありません"
              :suffix="currentForm.tableFilterId ? 'の卓の' : ''"
              clearable
              :menu-props="{ auto: true, offsetY: true }"
              @change="currentForm.tableFilterId = $event ? $event.tableFilterId : null"
              @click:clear="currentForm.tableFilterId = null"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              :value="currentForm.calcSalesTarget"
              :items="calcSalesTargets"
              item-value="value"
              item-text="text"
              label="算出対象範囲"
              :error="!currentForm.calcSalesTarget"
              :menu-props="{ auto: true, offsetY: true }"
              @change="currentForm.calcSalesTarget = $event"
            >
              <template #selection="{ item }">
                <span>
                  <v-icon
                    v-if="item.icon"
                    left
                    small
                  >
                    {{ item.icon }}
                  </v-icon>
                  {{ item.text }}
                </span>
              </template>

              <template #item="{ item }">
                <v-list-item-avatar
                  v-if="item.icon"
                  size="20"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </template>

      <template #group-header="{ groupBy, group }">
        <strong v-if="groupBy[0] === 'meta.targetName'">
          {{ group }}
        </strong>

        <strong v-if="groupBy[0] === 'attributes.calcSalesTarget'">
          {{ calcSalesTargets.find(o => o.value === group).text }}
        </strong>
      </template>
    </common-conditions>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  findIndex,
  groupBy,
  each,
  filter,
  map,
  includes,
} from 'lodash'
import {
  mdiCashMarker,
  mdiHeartOutline,
  mdiEye,
  mdiEyeOffOutline,
} from '@mdi/js'
import VendingSizeConditionApi from '@/api/admin/VendingSizeCondition'
import VendibleApi from '@/api/waiter/Vendible'
import VendibleTagApi from '@/api/waiter/VendibleTag'
import TableFilterApi from '@/api/admin/TableFilter'
import useCompInit from '@/views/composable/useCompInit'
import useCurrentData from '@/views/composable/useCurrentData'
import CommonConditions from '@/views/components/pay_condition/CommonConditions.vue'

export default {
  components: {
    CommonConditions,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const { currentClub } = useCurrentData()
    const isSubmitting = ref(false)
    const currentForm = ref({
      id: null,
      adjective: null,
      threshold: 0,
      calcSalesTarget: 'contributed',
      vendibleId: null,
      vendibleType: null,
      vendibleTagId: null,
      tableFilterId: null,
    })
    const relationType = ref('vendible')
    const relationTypes = { vendible: '商品', vendibleTag: '商品タグ' }
    const isOpenVendibleTagVendibles = ref(false)
    const calcSalesTargets = [
      { icon: mdiCashMarker, value: 'contributed', text: '売上' },
      { icon: mdiHeartOutline, value: 'received', text: '受領' },
      { value: 'both', text: '両方' },
    ]
    const vendingSizeConditions = ref([])
    const vendibles = ref([])
    const vendibleTags = ref([])
    const tableFilters = ref([])

    const isValid = computed(() => {
      const {
        vendibleId,
        vendibleType,
        vendibleTagId,
        calcSalesTarget,
      } = currentForm.value
      const isValidRelationVendible = vendibleId && vendibleType && !vendibleTagId
      const isValidRelationVendibleTag = !vendibleId && !vendibleType && vendibleTagId

      return {
        relation: isValidRelationVendible || isValidRelationVendibleTag,
        calcSalesTarget: !!calcSalesTarget,
      }
    })

    const vendibleItems = computed(() => {
      const lists = []
      const typeOrder = ['Course', 'Nomination', 'Item']
      const vendiblesGrouped = groupBy(vendibles.value, 'attributes.vendibleType')

      each(typeOrder, type => {
        if (!vendiblesGrouped[type]) return

        lists.push({
          header: {
            Course: currentClub.value.courseAlias,
            Nomination: currentClub.value.nominationAlias,
            Item: 'アイテム',
          }[type],
        })
        lists.push(...vendiblesGrouped[type])
      })

      return lists
    })

    const vendibleTagVendibles = computed(() => {
      if (!currentForm.value.vendibleTagId) return []

      return filter(vendibles.value, vendible => {
        return includes(
          map(vendible.attributes.vendibleTaggings.data, d => +d.attributes.vendibleTagId),
          +currentForm.value.vendibleTagId,
        )
      })
    })

    const cancelForm = () => {
      currentForm.value = {
        id: null,
        adjective: null,
        threshold: 0,
        calcSalesTarget: 'contributed',
        vendibleId: null,
        vendibleType: null,
        vendibleTagId: null,
        tableFilterId: null,
      }
    }

    const submit = async () => {
      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        adjective,
        threshold,
        calcSalesTarget,
        vendibleId,
        vendibleType,
        vendibleTagId,
        tableFilterId,
      } = currentForm.value

      const res = await VendingSizeConditionApi[`${fnVerb}VendingSizeCondition`]({
        id,
        adjective,
        threshold,
        calcSalesTarget,
        vendibleId,
        vendibleType,
        vendibleTagId,
        tableFilterId,
      })

      if (res?.data) {
        const { data } = res.data.vendingSizeCondition

        if (isUpdate) {
          const vendingSizeConditionIdx = findIndex(vendingSizeConditions.value, o => +o.id === +data.id)
          vendingSizeConditions.value.splice(vendingSizeConditionIdx, 1, data)
        } else {
          vendingSizeConditions.value.push(data)
        }

        vm.$toast.success(isUpdate ? '条件を更新しました' : '条件を追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const res = await VendingSizeConditionApi.deleteVendingSizeCondition(id)

      if (res) {
        const vendingSizeConditionIdx = findIndex(vendingSizeConditions.value, o => +o.id === +id)
        vendingSizeConditions.value.splice(vendingSizeConditionIdx, 1)
        vm.$toast.success('条件を削除しました')
      }
    }

    const getVendingSizeConditions = async () => {
      const res = await VendingSizeConditionApi.getVendingSizeConditions()

      if (res?.data) {
        vendingSizeConditions.value = [...res.data.vendingSizeConditions.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.data) {
        vendibles.value = [...res.data.vendibles.data]
      }
    }

    const getVendibleTags = async () => {
      const res = await VendibleTagApi.getVendibleTags()

      if (res?.data) {
        vendibleTags.value = [...res.data.vendibleTags.data]
      }
    }

    const getTableFilters = async () => {
      const res = await TableFilterApi.getTableFilters()

      if (res?.data) {
        tableFilters.value = [...res.data.tableFilters.data]
      }
    }

    initWith([
      getVendingSizeConditions(),
      getVendibles(),
      getVendibleTags(),
      getTableFilters(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      currentForm,
      vendingSizeConditions,
      vendibles,
      vendibleTags,
      tableFilters,
      relationTypes,
      relationType,
      isOpenVendibleTagVendibles,
      headers: [
        { text: 'ターゲット', value: 'meta.targetName' },
        {
          text: '算出対象範囲',
          value: 'meta.calcSalesTargetName',
        },
      ],
      calcSalesTargets,

      // computed
      isValid,
      vendibleItems,
      vendibleTagVendibles,

      // methods
      cancelForm,
      submit,
      destroy,

      icons: {
        mdiEye,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
